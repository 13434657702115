import React from 'react';
import Slide from 'react-reveal/Slide';


class ImageMount extends React.Component {

    //Takes position as a parameter and applies the class needed for correct 
    //alignment of text and images 
    alignImageAndText(position) {
        var containerClass= "wanderlusty-imagemount-container ";

        if(position == null) {
            position = "right-img-left-text";
        }

        containerClass = containerClass + position;

        return containerClass;
    }

    handleClick = () => {
        if(this.props.openOverlay) {
            this.props.openOverlay(this.props.imageIndex);
        }
        
    }

    determineEntryDirection() {
        const standardMarkup = <div className={this.alignImageAndText(this.props.imageProperties.position)}>
            <img src={this.props.imageProperties.image} alt={this.props.imageProperties.imageAlt} onClick={this.handleClick} />
            <div className="imagemount-details">
                <h3>{this.props.imageProperties.imageTitle}</h3>
                <p className="imagemount-film-details">{this.props.imageProperties.filmDetails}</p>
                <p className="imagemount-image-description">{this.props.imageProperties.description}</p>
            </div>
        </div>;

        var animatedMarkup = null;

        //<Slide fraction='0.35' mirror='false' left>
        if(this.props.imageProperties.position === 'left-img-right-text') {
            //Left
            animatedMarkup = <Slide className="image-mount-spacing" fraction={0.35}  left>
                {standardMarkup}
            </Slide>;
        } else if (this.props.imageProperties.position === 'top-img-lower-text') {
            //Center
            animatedMarkup = <Slide className="image-mount-spacing" fraction={0.45} top>
                {standardMarkup}
            </Slide>

        } else {
            //Since the null case defaults to 'right-img-left-text' this will handle that case
            animatedMarkup = <Slide className="image-mount-spacing" fraction={0.35} right>
                {standardMarkup}
            </Slide>
        }

        return animatedMarkup;
    }

    render() {
        return <div> 
            {this.determineEntryDirection()}
        </div>;
    }

}

export default ImageMount;


/*
    An Image Mount refers to how an image is displayed.  The full block of the component contains
    an image and a description which is optional.  The positioning / ratio of both the text and 
    image will be configurable on an image by image basis.
    The preconfigured configurations include:

    *****Landscape*****

    Landscape oriented images will have extra consideration given to width in order to

    1. Image left, text right:
    2. Image right, text left:
    3. Image center, text below:

    *****Portrait*****

    Portrait oriented images may require a bit more tweaking to get working properly as they


    1. Image left, text right:
    2. Image right, text left:
    3. Image center, text below:

    *****Pano*****
    1. Image on top - 100% width, text appears below, occupies approximately 80% and is left 
    aligned

*/


/*
const standardMarkup = <div className={this.alignImageAndText(this.props.imageProperties.position)}>
            <div className="imagemount-image">
                <img src={this.props.imageProperties.image} alt={this.props.imageProperties.imageAlt} onClick={this.handleClick} />
            </div>
            <div className="imagemount-details">
                <h3>{this.props.imageProperties.imageTitle}</h3>
                <p className="imagemount-film-details">{this.props.imageProperties.filmDetails}</p>
                <p className="imagemount-image-description">{this.props.imageProperties.description}</p>
            </div>
        </div>;
        */