import React from 'react';

class Album extends React.Component {

    populateBGImage() {
        const bgiStyle = {
            backgroundImage : 'url(' + this.props.album.albumCoverPhoto + ')'
        };

        return bgiStyle;
    };


    //this.populateBGImage()
    render() {
        
        return <div className="wanderlusty-album-container" >
            <div className="wanderlusty-bgi-filter" style={this.populateBGImage()}></div>
            <div className="wanderlusty-opacity-filter"></div>           
            <div className="wanderlusty-album-name">
                <h1>{this.props.album.albumName}</h1>
            </div>
        </div>;
    }
}

export default Album;


/*
Full width, cover sized background image.  Pano style ratio with a blur on cover photo.
On hover over, de blur and zoom shift on photo. 

Album name displays in large sans text black text outline with white text
{this.props.albumName}
*/