import React from 'react';

class ImageOverlay extends React.Component {

    constructor(props) {
        super(props);
        this.state = { 
            currentIndex: props.startingIndex,
            gallery : this.filterOutTextContent()
        };
    }

    filterOutTextContent() {
        var contentArray = [];
        this.props.galleryContents.forEach(content => {
            if(content.contentType === "image") {
                contentArray.push(content);
            }
        });
        return contentArray;
    }

    changeImage(direction) {
        var currentGallery = this.state.gallery;
        var newIndex = this.state.currentIndex + direction;
        
        if(newIndex >= currentGallery.length) {
            newIndex = 0;
        } else if(newIndex < 0) {
            newIndex = currentGallery.length - 1;
        }
    
        this.setState({ 
            currentIndex: newIndex,
            gallery : currentGallery
        });
    }

    render() {
        return <div className="wanderlusty-image-overlay">
            
            <div className="image-overlay-content">
                <div className="image-overlay-left">
                    <p onClick={() => this.changeImage(-1)}>&#10092;</p>
                </div>
                <div className="image-overlay-main-division">
                    <div className="image-overlay-image-container">
                        <img src={this.state.gallery[this.state.currentIndex].contentProperties.image} alt="" />
                    
                        <div className="image-overlay-image-detail">
                            <h3>{this.state.gallery[this.state.currentIndex].contentProperties.imageTitle} - <span className="overlay-film-info">{this.state.gallery[this.state.currentIndex].contentProperties.filmDetails}</span></h3>
                            <p>{this.state.gallery[this.state.currentIndex].contentProperties.description}</p>
                        </div>
                    </div>
                    
                </div>
                <div className="image-overlay-right">
                    <p className="overlay-exit" onClick={this.props.closeOverlay}>&#10005;</p>
                    
                    <p onClick={() => this.changeImage(1)}>&#10093;</p>
                </div>
            </div>
            <div className="image-overlay-background"></div>
        </div>;
    }
}

export default ImageOverlay;