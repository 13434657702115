import React from 'react';

import TextContent from '../text-content/TextContent';
import ImageMount from '../image-mount/ImageMount';
import ImageOverlay from '../image-overlay/ImageOverlay';
//import Loading from '../loading/Loading';

var images = require.context('./../../resources/albums',true);

class Gallery extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            imageDetailOpen : false,
            imageDetailIndex : 0,
            albumContents : this.props.album.albumContents
        };

        this.openOverlay = this.openOverlay.bind(this);
        this.closeOverlay = this.closeOverlay.bind(this);
        this.retrieveAlbumContent = this.retrieveAlbumContent.bind(this);

    }

    openOverlay(index) {
        this.setState ({
            imageDetailOpen : true,
            imageDetailIndex : index
        });
    }

    closeOverlay() {
        this.setState ({
            imageDetailOpen : false,
            imageDetailIndex : 0
        });
    };

    /*
    *  Iterate through list of gallery content and generate a JSX array for populating 
    *  page contents
    */
    getAlbumContent(contents) {
        var jsxContent = [];
        var count = 0;
        var imageCount = 0;

        var keyRoot = this.props.album.albumName+"Element";
        if(contents.length > 0) {
            contents.forEach(content => {
                if(content.contentType==="text") {
                    jsxContent.push(<TextContent key={keyRoot+count} textContent={content.content} />);
                    
                } else if(content.contentType==="image") {
                    jsxContent.push(<ImageMount key={keyRoot+count} imageProperties={content.contentProperties} imageIndex={imageCount} openOverlay={this.openOverlay} />);
                    imageCount++;
                }
                count++;
            });
        } else {
            //jsxContent.push(<Loading key='gallery-loading'></Loading>); //<p key='Loading'>Loading...</p>
        }

        return jsxContent;
    }

    renderOverlay() {
        var jsxReturn = null;
        
        if(this.state.imageDetailOpen) {
            jsxReturn = <ImageOverlay startingIndex={this.state.imageDetailIndex} galleryContents={this.props.album.albumContents} closeOverlay={this.closeOverlay} />;
        }
        
        return jsxReturn;
    }


    visibilityFunction = null;
    //Function used for determining if image is visible in viewport.  
    //Returns values between 0 (completely hidden) and 1 (completely visible).
    imageIsVisibileInViewport(event) {
        var images = document.getElementsByClassName("wanderlusty-imagemount-container");
        var iHeight = (window.innerHeight || document.documentElement.clientHeight);
        var bounding = images[1].getBoundingClientRect();

        if(!((bounding.top + bounding.height <= 0) || (bounding.bottom - bounding.height > iHeight))) {
            console.log("Element is visible");
        } else {
            console.log("Element is NOT visible");
        }
    }

    componentDidMount() {
        /*this.visibilityFunction = (event) => this.imageIsVisibileInViewport(event);
        document.addEventListener('scroll', this.visibilityFunction);*/

        if(this.state.albumContents.length === 0) {
            //Call service to retrieve album contents
            console.log("Retrieving album content from DB for album: " + this.props.album.albumId);
            this.retrieveAlbumContent(this.props.album.albumId);
        } else {

            console.log("Album already loaded.");
        }


    }

    componentWillUnmount() {
        //document.removeEventListener('scroll', this.visibilityFunction);
    }



    //Handles REST call to album content service
    retrieveAlbumContent(albumId) {
        fetch("https://destinationsdeveloped.com/rest/retrieveAlbumContents?albumId="+albumId, {
        method : 'GET',
        headers : {
            'Content-Type' : 'application/json'
        }
        }).then(response => {
            return response.json();
        }).then((contentResponseObject) => {
            //Setting proper image reference
            contentResponseObject.albumContent.forEach(item => {
                if(item.contentType === 'image') {
                    item.contentProperties.image = images(item.contentProperties.image);
                }
            });
            
            this.setState({
                imageDetailOpen : false,
                imageDetailIndex : 0,
                "albumContents" : contentResponseObject.albumContent
            });
            this.props.stateUpdate(this.props.album.albumId, this.state.albumContents);
        });
    };


    render() {

        return <div className="wanderlusty-gallery-container">
            <div className="wanderlusty-gallery-title">
                <h2>{this.props.album.albumName}</h2>
            </div>
            <div className="wanderlusty-gallery-content">
                {
                    this.getAlbumContent(this.state.albumContents)
                }        
            </div>
            
            <div>
                {
                    this.renderOverlay()
                }
            </div>
        </div>;
    }

}

export default Gallery;


/*
<Loading key='gallery-loading'></Loading>
Centered title (Garda font) with pictures arranged underneath.  Brief description optional.  
Description text should be Galaxie Polaris.  The pictures are arranged with care, no 
alternating, no formula, each image should be configured and displayed in a way that fits 
the image.  If we're talking analogies, it should be closer to a curated museum display,
not a cataloged archive.  Selecting an image from here will bring up a full screen overlay.
*/ 