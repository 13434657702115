import React from 'react';
import './App.scss';
// eslint-disable-next-line
import { BrowserRouter as Router, Route, Link } from "react-router-dom";

import Header from './components/header/Header';
import Footer from './components/footer/Footer';
import Home from './components/home/Home';
import About from './components/about/About';

import Gallery from './components/gallery/Gallery';

//I really don't like this, but it's necessary for now
var images = require.context('./resources/albums',true);

class App extends React.Component {
  constructor(props) {
    super(props);
  
    this.state = {
      "albums" : []
    };

    this.updateStateContent = this.updateStateContent.bind(this);
  }

  componentDidMount() {
    //Call svc to load data
    if(!this.state.albums.length > 0) {
      this.retrieveAlbums();
    }
  }

  /*
  *  Handles REST call to album service
  */
  retrieveAlbums() {
    //fetch("/retrieveAlbums?transId=1234Dinosaur", {
    fetch("https://destinationsdeveloped.com/rest/retrieveAlbums?transId=1234Dinosaur", {
      method : 'GET',
      headers : {
        'Content-Type' : 'application/json',
        'Accept' : 'application/json'
      }
    }).then(response => {
        return response.json();
    }).then((albums) => {
      
      this.setState({
        "albums" : this.processAlbumData(albums)
      });
    });
  };

  /*
  *  Takes raw data from service and processes it into expected format
  */
  processAlbumData(albums) {
    var returnedAlbums = [];
      var tempAlbum = null;
      albums.albumList.forEach(album => {
        tempAlbum = {
          "albumId" : album.album_id,
          "albumName" : album.album_name,
          "albumAlt" : "",
          "albumRef" : album.album_name.replace(/\s/g, '').toLowerCase(),  //Remove spaces and convert to lowercase
          "albumCoverPhoto" : images(album.image),
          "albumContents" : []
        };
        returnedAlbums.push(tempAlbum);
      });

      return returnedAlbums;
  }

  /*
  *  Used to update parent state when galleries retrieve contents.  
  *  Will prevent a future call from being made
  */
  updateStateContent(albumId, albumContent) {
    var stateCopy = this.state.albums;
    stateCopy.forEach(album => {
      if(album.albumId === albumId) {
        album.albumContents = albumContent;
      }
    });
    
    this.setState({
      "albums" : stateCopy 
    });
  
  }

  
  render() {
    return <div>
      
      <Router>
        <Header />
        <div className="top-spacer"></div>
        <div className="wanderlusty-content">
          

          <Route path='/' exact render={(props) => <Home albums={this.state.albums}></Home>}/>
          {
            this.state.albums.map(album => <Route key={album.albumRef+"Route"} path={"/"+album.albumRef+"/"} render={(props) => <Gallery album={album} stateUpdate={this.updateStateContent}></Gallery>}/>)
          }
          <Route path='/about/' render={(props) => <About />} />
        </div>
        <Footer />
      </Router>
      
    </div>
    
  }
}

export default App;