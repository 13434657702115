import React from 'react';
// eslint-disable-next-line
import { BrowserRouter as Router, Link } from "react-router-dom";

import Album from '../album/Album';

class Home extends React.Component {

    render() {
        return <div>
            {
                this.props.albums.map(album => <Link key={album.albumRef+"Link"} to={album.albumRef}><Album album={album} /></Link>)
            }  
        </div>
    }
}

export default Home;