import React from 'react';
import Fade from 'react-reveal';

class TextContent extends React.Component {

    testParam() {
        return "bottom";
    }

    render() {
        return <Fade bottom>
            <div className="wanderlusty-text-content">
                <p>
                    {this.props.textContent}
                </p>
            </div>
        </Fade>
    }

}

export default TextContent;