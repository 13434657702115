import React from 'react';
// eslint-disable-next-line
import { BrowserRouter as Router, Link } from "react-router-dom";

import Icon from '../../resources/film-roll-256.png';

class Footer extends React.Component {

    render() {

        var date = new Date().getFullYear();

        return <div className="wanderlusty-footer">
            <Link to="/about/"><img src={Icon} alt='Film icon.'/></Link>
            <p>All images &copy; {date}</p>
        </div>
    }
}

export default Footer;