import React from 'react';
import TextContent from '../text-content/TextContent';

class About extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            textContent : "Destinations Developed is a collection of photographs that I've taken during my travels.  Everything shown here was made using 35mm film and has undergone minimal editing, namely only cropping and some minor levels adjustment.  The idea is to showcase the character of these locations, but with a focus on the smaller details.  I wanted to capture the things that you might not find as easily in a way that accurately reflected their true state.  Without filters to create a perfect scene, the true imperfect beauty of the destination can be fully seen."
        } 

    }


    render() {
        return <div>
           <h3>What is This?</h3>
           <TextContent textContent={this.state.textContent}></TextContent>
        </div>
    }
}

//<ImageMount imageProperties={this.state.aboutProperties} />

export default About;