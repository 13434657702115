import React from 'react';
// eslint-disable-next-line
import { BrowserRouter as Router, Link } from "react-router-dom";

class Header extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            "headerCollapsed" : false
        }
    }

    expandOrCollapseHeader() {
        var classString = "expansion-container ";

        if(this.state.headerCollapsed) {
            classString = classString + "collapsed";
        } else {
            classString = classString + "expanded";
        }

        //console.log(window.scrollY);

        return classString;
    }


    updateCollapseState() {
        var shouldCollapse = false;
        if(window.scrollY > 70) {
            shouldCollapse = true;
        }

        //To prevent constant re-rendering...
        if(this.state.headerCollapsed !== shouldCollapse) {
            console.log("Render...");
            this.setState({
                "headerCollapsed" : shouldCollapse
            });
        }
    }
    

    componentDidMount() {
        //this.visibilityFunction = (event) => this.imageIsVisibileInViewport(event);
        document.addEventListener('scroll', this.updateCollapseState.bind(this));
    }

    componentWillUnmount() {
        document.removeEventListener('scroll', this.updateCollapseState);
    }

    render() {
        const headerFormat = <div className='wanderlusty-header'>
            
            <div className={this.expandOrCollapseHeader()}>
                <Link to="/"><h1>Destinations Developed</h1></Link>
                <h3 className="wanderlusty-subtitle">A Love Letter to Travel, Photography, and Film</h3>
            </div>
            
        </div>;


        return headerFormat;
    }

}

export default Header;
//<div className="wanderlusty-header-border wanderlusty-header-top-border"></div>
//<div className="wanderlusty-header-border wanderlusty-header-bottom-border"></div>

/*
<div className="expansion-container expanded clip-text-maybe">
    <Link to="/"><h1>Destinations <br /> Developed</h1></Link>
    <h3 className="wanderlusty-subtitle">A Love Letter to Travel, Photography, and Film</h3>
</div>
*/